<template>

    <tr>
      <td style="width: 50%">
        <div class="row mb-2">
          <div class="col-md-6">
            {{itemName}}
          </div>
          <div class="col-md-6">
            <span class="font-16"> {{itemValue | toCurrencyFormat }} </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <b-form-checkbox switch v-model="useInWeeklyPayment" @change="onSwitchChange('wf','use_in_weekly_payment')">
              W. Payments
            </b-form-checkbox>
          </div>
          <div class="col-md-6">
            <b-form-checkbox  switch v-model="useInFinancialAdministration" @change="onSwitchChange('wf','use_in_financial_administration')"
            >
              F. Administration
            </b-form-checkbox>
          </div>
        </div>
        <b-form-group>
          <div class="row mt-2">
            <div class="col-md-6">
              <b-form-radio v-model="incomeCostType" @change="onSwitchChange('ic','field_income_cost_type', 'income')"  value="income">Verkoop</b-form-radio>
            </div>
            <div class="col-md-6">
              <b-form-radio v-model="incomeCostType" @change="onSwitchChange('ic','field_income_cost_type', 'cost')" value="cost">Inkoop</b-form-radio>
            </div>

          </div>
        </b-form-group>

        <template v-if="srcOfField === 'providers'">
           From <span class="text-danger">{{providerName}}</span>
        </template>
<!--        <template v-if="srcOfField === 'receipts'">-->
<!--         <hr>-->
<!--         <div class="row">-->
<!--           <div class="col-md-4">INV No: <br> <span class="text-danger">{{getInvoiceDetails(extra)['invNo']}}</span></div>-->
<!--           <div class="col-md-4">date: <br> <span class="text-danger">{{getInvoiceDetails(extra)['date']}}</span></div>-->
<!--           <div class="col-md-4">Place: <br> <span class="text-danger">{{getInvoiceDetails(extra)['place']}}</span></div>-->
<!--         </div>-->
<!--        </template>-->
      </td>
      <!--                               style="width: 10%"-->
      <td class="" style="width: 50%">
        <div class="row mb-2">
          <div class="col-md-6">
            {{taxName}}
          </div>
          <div class="col-md-6">
            <span class="font-18"> {{ taxValue | toCurrencyFormat}} </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <b-form-checkbox switch v-model="useTaxInWeeklyPayment"  @change="onSwitchChange('wf','use_tax_in_weekly_payment')">
              W. Payments
            </b-form-checkbox>
          </div>
          <div class="col-md-6">
            <b-form-checkbox  switch v-model="useTaxInFinancialAdministration"
                              @change="onSwitchChange('wf','use_tax_in_financial_administration')"
            >
              F. Administration
            </b-form-checkbox>
          </div>
        </div>
        <b-form-group>
          <div class="row mt-2">
            <div class="col-md-6">
              <b-form-radio v-model="taxIncomeCostType" @change="onSwitchChange('ic','tax_field_income_cost_type', 'income')" value="income">Verkoop</b-form-radio>
            </div>
            <div class="col-md-6">
              <b-form-radio  v-model="taxIncomeCostType" @change="onSwitchChange('ic','tax_field_income_cost_type', 'cost')" value="cost">Inkoop</b-form-radio>
            </div>

          </div>
        </b-form-group>
      </td>

    </tr>
</template>

<script>
import {financialAdministrationService} from "../../../../apis/financial.administration.service";
import moment from "moment";

export default {
  name: "f-data-item",
  props: ['item', 'week'],
  data(){
    return {
      itemName: '',
      itemValue: '',
      useInWeeklyPayment: '',
      useInFinancialAdministration: '',
      incomeCostType: '',
      taxName: '',
      taxValue: '',
      useTaxInWeeklyPayment: '',
      useTaxInFinancialAdministration: '',
      taxIncomeCostType: '',
      extra: null,
      providerName: '',
      srcOfField: ''
    }
  },
  methods: {
    onSwitchChange(type, field, value){
     // console.log('id',this.item['id'])

      if(type === 'wf'){
        switch (field){
          case 'use_in_weekly_payment':
            value = this.useInWeeklyPayment;
            break;
          case 'use_in_financial_administration':
            value = this.useInFinancialAdministration;
            break;
          case 'use_tax_in_weekly_payment':
            value = this.useTaxInWeeklyPayment;
            break;
          case 'use_tax_in_financial_administration':
            value = this.useTaxInFinancialAdministration;
            break;
        }
      }

      const payload = {
        field: field,// wf - weekly pmnt / f.admin ---- ic - income / cost
        value: value,
        week: this.week,
        user_id: this.item['user_id'],
        field_name: this.item['field_name'],
        src_of_field: this.item['src_of_field']
      }

      console.log('payload', payload)
      this.$store.dispatch('showLoader')

      // alert('modifying this feature');

      financialAdministrationService.updatePreferences(payload).then((data) => {
        this.$store.dispatch('hideLoader')
      })

    },
    // getInvoiceDetails(invAsString){
    //   const inv = JSON.parse(invAsString);
    //   const dataOnReceipt = JSON.parse(inv['data_on_ext_receipts']);
    //   console.log('invoice ', dataOnReceipt)
    //   return {
    //     invNo: dataOnReceipt['invoiceNumber'],
    //     date: moment(dataOnReceipt['invoiceDate']).calendar(),
    //     place: dataOnReceipt['invoicePlace'],
    //   };
    // }
  },
  mounted() {
    // console.log('item', this.item)
    // console.log('mounted called');
    // console.log('check here: ',this.item['field_name'], this.item['field_value']);

    this.itemName = this.item['field_name'];
    this.itemValue = this.item['field_value'];
    this.useInWeeklyPayment = this.item['use_in_weekly_payment'] === "1"
    this.useInFinancialAdministration = this.item['use_in_financial_administration'] === "1"
    this.incomeCostType = this.item['field_income_cost_type']
    this.extra = this.item['extra']
    this.srcOfField = this.item['src_of_field']
    this.providerName = this.item['provider_name']

    this.taxName = 'BTW ' + this.item['field_name'];
    this.taxValue = this.item['tax_value_on_field']
    this.taxIncomeCostType = this.item['tax_field_income_cost_type']
    this.useTaxInWeeklyPayment = this.item['use_tax_in_weekly_payment'] === "1"
    this.useTaxInFinancialAdministration = this.item['use_tax_in_financial_administration'] === "1"

  }
}
</script>

<style scoped>

</style>