<template>
  <p class="my-4">Hello from modal!</p>
</template>

<script>
export default {
  name: "prepare-weekly-payment"
}
</script>

<style scoped>

</style>