<template>
  <form action="#" @submit.prevent="handleSubmit">

    <div class="form-group">
      <label>Week</label>
      <input type="week" v-model="week"  class="form-control" >
    </div>

    <div class="form-group">
      <label >Field name</label>
      <input type="text" v-model="name" class="form-control" placeholder="Specify the field name">
    </div>

    <div class="form-group">
      <label for="amount">Amount</label>
      <input type="number" v-model="amount" step="0.01" id="amount" class="form-control" placeholder="Amount">
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label>Tax Formula (optional)</label>
          <input type="text"  v-model="tax_formula" class="form-control" placeholder="eg. ref * 9 / 100">
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>Tax Type</label>
          <select name="" id="" class="form-control" v-model="tax_type_on_field">
            <option value="">-- choose one --</option>
            <option value="lower">Lower Tax</option>
            <option value="upper">Upper Tax</option>
          </select>
        </div>
      </div>
    </div>

    <hr>

    <b-form-group>
      <div class="row mt-2">
        <div class="col-md-6">
          <b-form-radio v-model="field_income_cost_type" name="field_income_cost_type" value="income">Verkoop</b-form-radio>
        </div>
        <div class="col-md-6">
          <b-form-radio v-model="field_income_cost_type" name="field_income_cost_type" value="cost">Inkoop</b-form-radio>
        </div>

      </div>
    </b-form-group>

    <b-form-group>
      <div class="row mt-2">
        <div class="col-md-6">
          <b-form-radio
              v-model="grossOrNet" :name="'gross-net'"
              value="gross">Gross
          </b-form-radio>
        </div>
        <div class="col-md-6">
          <b-form-radio
              v-model="grossOrNet"
              :name="'gross-net'"
              value="net">Net
          </b-form-radio>
        </div>

      </div>
    </b-form-group>

    <div class="row">

      <div class="col-md-6">
        <div class="form-group">
          <b-form-checkbox switch v-model="use_in_weekly_payment">
            use amount for weekly payment
          </b-form-checkbox>
        </div>
      </div>

      <div class="col-md-6">
        <div class="form-group">
          <b-form-checkbox switch v-model="use_in_financial_administration">
            use amount for  financial administration
          </b-form-checkbox>
        </div>
      </div>

    </div>

    <div class="row">

      <div class="col-md-6">
        <div class="form-group">
          <b-form-checkbox switch v-model="use_tax_in_weekly_payment">
            use tax in weekly payment
          </b-form-checkbox>
        </div>
      </div>

      <div class="col-md-6">
        <div class="form-group">
          <b-form-checkbox switch v-model="use_tax_in_financial_administration">
            use tax in financial administration
          </b-form-checkbox>
        </div>
      </div>

    </div>

    <hr>

    <div class="row mt-4">
      <div class="col-md-12 text-center">
        <template v-if="isLoading">
          <b-button variant="primary" class="mr-2" disabled>
            <b-spinner small></b-spinner>
            <span class="sr-only">Loading...</span>
          </b-button>

          <b-button variant="primary" disabled>
            <b-spinner small type="grow"></b-spinner>
            Loading...
          </b-button>
        </template>
        <template v-else>
          <button class="btn btn-info">
            Submit financial data
          </button>
        </template>
      </div>
    </div>


  </form>
</template>

<script>
import {financialAdministrationService} from "../../../../apis/financial.administration.service";

export default {
  name: "add-extra-f-data",
  props: ['week', 'driver'],
  data(){
    return {
      isLoading: false,
      amount: '',
      name: '',
      field_income_cost_type: 'cost',
      grossOrNet: '',
      tax_type_on_field: '', // upper or lower
      use_in_weekly_payment: false,
      use_in_financial_administration: false,
      use_tax_in_weekly_payment: false,
      use_tax_in_financial_administration: false,
      tax_formula: ''
    }
  },
  methods: {
    handleSubmit(){
      const payload = {
        amount: this.amount,
        name: this.name,
        field_income_cost_type: this.field_income_cost_type,
        grossOrNet: this.grossOrNet,
        tax_type_on_field: this.tax_type_on_field, // upper or lower
        use_in_weekly_payment: this.use_in_weekly_payment,
        use_in_financial_administration: this.use_in_financial_administration,
        use_tax_in_weekly_payment: this.use_tax_in_weekly_payment,
        use_tax_in_financial_administration: this.use_tax_in_financial_administration,
        tax_formula: this.tax_formula,
        driver_id: this.driver['id'],
        week: this.week
      }

      this.isLoading = true;
      financialAdministrationService.addExtraFinancialData(payload).then(data => {
        this.isLoading = false;

        if(!data.status){
          this.$store.dispatch('error', {message:data.message, showSwal: true})
          return;
        }

        this.$emit('onFDataAdded', data.extra['data'])

        this.$store.dispatch('success', {message: 'Financial data added', showSwal: true})

      })

    }
  }
}
</script>

<style scoped>

</style>