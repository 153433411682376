<template>
  <div class="row">
    <div class="col-md-6 mb-3">
      <div class="text-left">
        <button class="btn btn-success" @click="$router.push('/f-admin/search-financial-data')"> <i class="fa fa-search" /> Search financial Data </button>
      </div>
    </div>
    <div class="col-md-6 mb-3">

      <div class="text-right">
        <button class="btn btn-danger" @click="generatePayout"> Send {{selectedWeek}} to weekly payment </button>
      </div>
    </div>
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <Sidepanel @onDriverSelected="onDriverSelected" :driverSelect="true" />
          <div class="inbox-rightbar">
            <!--              <Toolbar />-->
              <template v-if="driver">

                <div class="mt-0">
                  <h5 class="font-18">
                    <span class="mr-3">{{driver['name']}} {{driver['other_names']}}</span>
                    <template v-if="driver['prospective_driver'] && driver['prospective_driver']['service_type']">
                      <span class="badge badge-dark p-1"> {{driver['prospective_driver']['service_type'] | serviceType}}</span>
                    </template>
                    <template v-else>
                      (No service selected)
                    </template>

                    <template v-if="driver['driver'] && driver['driver']['dossier']">
                      <span class="badge badge-danger p-1 ml-2"> Dossier: {{driver['driver']['dossier']}}</span>
                    </template>
                    <template v-else>
                      <span class="badge badge-danger p-1 ml-2"> Dossier: 'N/A'</span>
                    </template>

                  </h5>
                </div>
                <hr>

                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                          <label>Filter by week</label>
                          <input type="week" class="form-control" v-model="selectedWeek" @change="onFilterChanged">
                        </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label>Filter by Income / Cost</label>
                        <select class="form-control" v-model="selectedIncomeCostType" @change="onFilterChanged">
                          <option value="">All</option>
                          <option value="income">Income</option>
                          <option value="cost">Cost</option>

                        </select>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group">
                        <label>Filter by source of data</label>
                        <select class="form-control" v-model="selectedScrOfData" @change="onFilterChanged">
                          <option value="">All</option>
                          <option value="rentals">From rentals</option>
                          <option value="providers">From providers</option>
                          <option value="service_charges">From service charge</option>
                          <option value="extra">From extra</option>
                          <option value="receipts">From receipts</option>
                        </select>
                      </div>
                    </div>

                </div>
                <hr>

                <!--              Tapped Image should show here-->
                <template v-if="data">
                  <template v-for="d in data">

                    <template v-for="(values,key) in d">

                      <div class="row">
                        <div class="col-md-12">

                          <h4 class="card-title">
                            <template v-if="key === 'providers'"> From provider data upload </template>
                            <template v-else-if="key === 'service_charges'"> From service charge</template>
                            <template v-else-if="key === 'receipts'"> From uploaded Receipts</template>
                            <template v-else-if="key === 'rentals'"> From car rentals </template>
                            <template v-else> {{key}} </template>
                          </h4>

                          <table class="table table-striped table-bordered">
                            <template v-for="(item) in values">
                              <template v-if="item['field_type'] === 'calculated'">

                                <f-data-item :item="item" :week="selectedWeek" :key="item['id']"></f-data-item>
                                <f-data-button-actions @requires-refresh="fetchFinancialData" :item="item" :week="selectedWeek" :driver="driver"></f-data-button-actions>
                                <tr>
                                  <td colspan="2" class="bg-soft-dark"></td>
                                </tr>
                              </template>

                            </template>

                          </table>

                        </div>
                      </div>

                    </template>
                  </template>

                </template>


                <template v-if="hasPaymentPlan">
                  <hr>

                  <div class="form-group">
                    <label for="arrear_formula">Formula (per week)</label>
                    <input id="arrear_formula" type="text" class="form-control"  >
                  </div>

                </template>
                <hr>

                <template v-if="driver">
                  <b-button v-b-modal.add-f-data class="btn btn-primary mr-2" >Add new financial data for {{driver['name']}} {{driver['other_names']}}</b-button>
                  <b-modal id="add-f-data" ok-title="Cancel" ok-only hide-footer size="lg" :title="'Financial data for ' + driver['name'] + ' ' + driver['other_names'] ">
                      <add-extra-f-d-ata :week="selectedWeek" :driver="driver" @onFDataAdded="onFDataAdded"></add-extra-f-d-ata>
                  </b-modal>
                </template>

              </template>

          </div >

        </div>
        <!-- card -->
      </div>
      <!-- end Col-9 -->
<!--      <b-modal id="weekly-payment" title="Send data to weekly payment">-->
<!--        <prepare-weekly-payment></prepare-weekly-payment>-->
<!--      </b-modal>-->

    </div>
  </div>
</template>

<script>
import Sidepanel from "./../payouts/sidepanel";

import PrepareWeeklyPayment from"./prepare-weekly-payment"

import fDataItem from "./f-data-item"
import fDataNotices from "./f-data-notices"
import fDataButtonActions from "./f-data-button-actions"
import {financialAdministrationService} from "../../../../apis/financial.administration.service";
import AddExtraFDAta from './add-extra-f-data'
import {payoutService} from "../../../../apis/payout.service";
import {confirm} from "../../../../utils/functions";

/**
 * Read-email component
 */
export default {
  components: {
    Sidepanel,
    fDataItem,
    fDataNotices,
    fDataButtonActions,
    PrepareWeeklyPayment,
    AddExtraFDAta
  },
  data() {
    return {
      driver: null,
      selectedWeek: '',
      data: null,
      selectedIncomeCostType: '',
      selectedScrOfData: '',
      hasPaymentPlan: false,
    };
  },


  watch: {
    driver: function (){
      this.fetchFinancialData();
    }
  },

  methods: {

    generatePayout() {

      confirm('send ' + this.selectedWeek + ' to weekly payout', () => {
        const payload = {
          'week': this.selectedWeek,
        }

        this.$store.dispatch('showLoader')
        payoutService.generatePayout(payload).then((data) => {
          this.$store.dispatch('hideLoader')

          if(!data.status){
            this.$store.dispatch('error', {message:data.message, showSwal: true})
            return;
          }

          // this.payout = data['extra']['payoutSummary'] ? data['extra'] : null;
          // console.log('payout', this.payout)
          this.$store.dispatch('success', {message: this.selectedWeek + ' has been sent to weekly payment', showSwal: true})

        })
      })

    },

    onDriverSelected(driver){
      if(!driver){
        return;
      }
      this.driver = driver;
    },

    onFilterChanged(){
      this.fetchFinancialData();
    },

    onFDataAdded(data){
      this.$bvModal.hide('add-f-data')
      this.fetchFinancialData();
    },

    fetchFinancialData(){
      if(!this.driver){
        return;
      }

      const payload = {
        'driver_id': this.driver['id'],
        'week': this.selectedWeek,
        'src_of_field': this.selectedScrOfData,
        'income_cost_type': this.selectedIncomeCostType,
      }
      // this.$store.dispatch('getFinancialData', payload);

      this.$store.dispatch('showLoader')
      financialAdministrationService.getFinancialData(payload).then(data => {
        this.$store.dispatch('hideLoader')

        if(!data.status){
          this.$store.dispatch('error', {message:data.message, showSwal: true})
          return;
        }

        // this.data = data.extra.map(function (key, item) {
        //   const arr = [];
        //   arr.push(key, item)
        //   return arr;
        // })
        // const d = data.extra['data'];
        this.data = data.extra['data']
        console.log('financial data', this.data)
        //data.map()
        //this.data = data.extra['data'];
        this.selectedWeek = data.extra['week'];

        //this.$forceUpdate();


      })

    },


  },

  mounted() {
    OneSignal.on('notificationDisplay', (event) => {
      let {data} = event;
      if(data.title==='processing_weekly_payment'){
        this.$store.dispatch('setProcessingInfo',event.heading);
      }
    });
  }

};
</script>

