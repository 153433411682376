<template>
  <tr>
    <td colspan="2">
      <div class="text-center">

        <ul class="list-inline">
<!--          v-if="invoice"-->
<!--            invoice['last_notification_sent']-->
            <li class="list-inline-item mr-2"><router-link class="text-danger" :to="'/invoices/list/' + invoice['id']">Invoice generated on {{formatDate(invoice['last_notification_sent'])}}. Click here to view invoice</router-link> <span class="text-bold text-info">({{invoice['status']}})</span></li>
        </ul>
      </div>
    </td>
  </tr>
</template>

<script>
import moment from "moment";

export default {
  props: ['item'],
  name: "f-data-notices",
  data(){
    return {
      invoice: null,
    }

  },
  methods: {
    formatDate(date){
      return moment(date).calendar().toLowerCase()
    }
  },
  created() {
    this.invoice = this.item['invoice']
  }
}
</script>

<style scoped>

</style>