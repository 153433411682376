<template>
  <tr>
    <td colspan="2">
<!--      <form class="form-inline">-->
        <div class="text-center">
          <button class="btn btn-outline-dark mr-1" v-b-modal="'f-item-'+item['id']" v-if="(item['field_income_cost_type'] === 'cost') || (item['tax_field_income_cost_type'] === 'cost')" > <i class="fas fa-file-invoice"></i> send new invoice </button>
          <button class="btn btn-outline-danger" @click="deleteItem"> <i class="fas fa-file-invoice"></i> delete record </button>
          <!-- The modal -->
          <b-modal :id="'f-item-'+item['id']"
                   :title="'Send invoice for ' + item['field_name']"
                   @ok="handleSubmit"
          >

            <div class="form-group">
              <label>Selected week</label>
              <input type="week" :value="week" disabled class="form-control">
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>{{item['field_name']}}  in €</label>
                  <input type="text" :value="item['field_value']" disabled class="form-control">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>BTW {{item['field_name']}} in €</label>
                  <input type="text" :value="item['tax_value_on_field']" disabled class="form-control">
                </div>
              </div>
            </div>

            <div class="form-group">
              <label>Select option</label>
              <select id="" class="form-control" required v-model="option">
                <option value="">-- choose one --</option>
                <option value="taxFree">Send invoice without tax (tax free)</option>
                <option value="withTax">Send invoice with tax</option>
                <option value="onlyTax">Send only tax as invoice</option>
              </select>
            </div>

            <div class="form-group">
              <label>Choose invoice due date</label>
              <input type="datetime-local" :min="tomorrow" class="form-control" v-model="deadline">
            </div>

<!--            <div class="form-group">-->
<!--              <button class="btn btn-primary">Send invoice now</button>-->
<!--            </div>-->

          </b-modal>
        </div>
<!--      </form>-->
    </td>
  </tr>
</template>

<script>
import {confirm} from "../../../../utils/functions";
import moment from "moment";
import {financialAdministrationService} from "../../../../apis/financial.administration.service";

export default {
  props: ['item', 'week', 'driver'],
  name: "f-data-button-actions",
  data(){
    return {
      option: '',
      deadline: ''
    }
  },

  computed: {
    tomorrow(){
      return moment().add(1,'days');
    }
  },

  methods: {
    handleSubmit(){
      if(this.option === ''){
        this.$store.dispatch('error', {message: 'please select an option', showSwal: true})
        return;
      }
      if(this.deadline === ''){
        this.$store.dispatch('error', {message: 'please select due date', showSwal: true})
        return;
      }
      const driverName = this.driver['name'] + ' ' + this.driver['other_names']
      let message = '';
      if(this.option === 'taxFree'){
        const amount = this.$options.filters.toCurrencyFormat(this.item["field_value"])
        message = 'Send '+amount+' as invoice for ' + this.item['field_name'] + ' to ' + driverName
      }else if(this.option === 'withTax'){
        const a = JSON.parse(this.item["field_value"]) + JSON.parse(this.item["tax_value_on_field"]);
        const amount  = this.$options.filters.toCurrencyFormat(a)
        message = 'Send '+amount+' as invoice for ' + this.item['field_name'] + ' to ' + driverName
      }else if(this.option === 'onlyTax'){
        const amount = this.$options.filters.toCurrencyFormat(this.item["tax_value_on_field"])
        message = 'Send '+amount+' as invoice for ' + this.item['field_name'] + ' to ' + driverName
      }

      confirm(message, () => {
          const payload = {
              option: this.option,
              week: this.week,
              user_id: this.item['user_id'],
              field_name: this.item['field_name'],
              src_of_field: this.item['src_of_field'],
              deadline: this.deadline
          }
          this.$store.dispatch('showLoader')
          financialAdministrationService.sendInvoice(payload).then((data) => {
            this.$store.dispatch('hideLoader')
            if(!data.status){
              this.$store.dispatch('error', {message: data.message, showSwal: true})
              return
            }

            this.$emit('requires-refresh')

            this.$store.dispatch('success', {message: data.message, showSwal: true})
          })

      })
    },
    deleteItem(){
      const message = "Delete " + this.item['field_name'] + ". This action cannot be reversed";

      confirm(message, () => {

        this.$store.dispatch('showLoader')
        //'week' => 'required',
        // 'user_id' => 'required',
        //     'field_name' => 'required',
        //     'src_of_field' => 'required'
        const payload = {
          week: this.week,
          user_id: this.item['user_id'],
          field_name: this.item['field_name'],
          src_of_field: this.item['src_of_field']
        }
        financialAdministrationService.deleteFDataItem(payload).then((data) => {
          this.$store.dispatch('hideLoader')
          if(!data.status){
            this.$store.dispatch('error', {message: data.message, showSwal: true})
            return
          }

          this.$emit('requires-refresh')

          this.$store.dispatch('success', {message: data.message, showSwal: true})
        })

      })
    }
  },
  created() {
    console.log('item', this.item['field_value'])
  }
}
</script>

<style scoped>

</style>